.p {
    margin: 0;
}

.s {
    font-size: 14px;
    line-height: 24px;
}

.m {
    font-size: 16px;
    line-height: 24px;
}

.l {
    font-size: 18px;
    line-height: 29px;
}