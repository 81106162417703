.footer {
    display: none;
}

.footer a:hover {
    color: var(--lightblue);
}

@media (max-width: 600px) {
    .footer {
        display: grid;
        /*gap: 10px 50px;*/
        color: var(--primary);
        font-size: 16px;
        line-height: 16px;
        justify-items: end;
        padding: 25px 30px;
        grid-template-columns: 1fr;
    }

}