.homeHeader {
    display: grid;
    /*gap: 0 5px;*/
    font-size: 21px;
    line-height: 5vh;
    padding: 5px 10px;
}

.homeHeader a {
    font-weight: bold;
    letter-spacing: 1px;
    display: grid;
    color: var(--blue);
    align-items: center;
    justify-items: center;
}

.homeHeader i {
    display: grid;
    align-items: center;
    padding: 3px;
    color: var(--blue);
    font-size: 24px;
}

.homeHeader div {
    color: var(--blue);
    padding: 0 7px;
}

@media (max-width: 600px) {
    .header {
        grid-template-columns: 1fr;
    }


}