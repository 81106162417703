.tag {
    display: inline-block;

    box-sizing: border-box;
    margin-right: 5px;
    border-radius: 20px;
}

.s {
    padding: 5px 10px;

    font-size: 12px;
    line-height: 12px;
}

.m {
    padding: 5px 10px;

    font-size: 14px;
    line-height: 14px;
}

.ghost {
    border: 1px solid var(--lightblue);
    color: var(--lightblue);
}

.pink {
    color: white;
    background: var(--pink);

    font-weight: bold;
}

.blue {
    color: white;
    background: var(--blue);

    font-weight: bold;
}

.grey {
    color: var(--white-hover);
    background: grey;

    font-weight: bold;
}

.green {
    color: white;
    background: #96d096;

    font-weight: bold;
}
