.h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 35px;
    margin: 0;
}

.h2 {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    margin: 0;
}

.h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    margin: 0;
}