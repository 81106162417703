
.bar-first-step {
    display: grid;
    grid-column: 1/ span 3;
    grid-row: 4/ span 1;
    background-image: -webkit-linear-gradient(0deg, var(--blue) 49%, var(--pink) 51%);
    width: 100%;
    margin-top: 14px;
    height: 7px;
    border-radius: 30px;
}

.bar-second-step {
    display: grid;
    grid-column: 1/ span 3;
    grid-row: 4/ span 1;
    background-color: var(--blue);
    width: 100%;
    margin-top: 14px;
    height: 7px;
    border-radius: 30px;
}