$transition: 1.2s;

.a-link {
  //color: #fff;
  color: rgba(255, 255, 255, .4);
  font-size: 14px;
  text-decoration: none;
  margin: 5px 0 0 0;
}

.a-link:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #fff;
  font-size: 14px;
}

.custom-search {
  margin: 0 0 0 -20px;
  position: relative;
  width: 300px;
}

.header {
  display: none;
}

.custom-button {
  position: relative;
  margin: 0 0 0 -65px;
  color: rgba(255, 255, 255, .4);
}

.custom-button:hover {
  color: rgba(255, 255, 255, 1);
  text-decoration: underline;
}

.container {
  display: grid;
  grid-column: 2;
  grid-row: 3;
  justify-content: center;
  align-content: center;
  transform: translateY(-38vh) translateX(0vw);
  transition: all 0.9s ease-out;
}

.container-activate {
  display: grid;
  grid-column: 2;
  grid-row: 3;
  align-content: center;
  transform: translateY(-38vh) translateX(-5vw);
  transition: all 0.6s ease-out;
}

.container-activate .a-link {
  visibility: hidden;
}


.lang-wrapper {
  display: grid;
  grid-column-start: 3;
  align-content: start;
  margin-top: -5vh;
  z-index: 101;

}

.lang-after {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 0 7px;
  text-transform: uppercase;
  color: rgb(1, 80, 119);
  transition: all 0.7s ease-in-out;

}

.lang-before {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 0 7px;
  text-transform: uppercase;
  color: #fff;
}

.lang-a {
  display: grid;
  align-items: center;
  font-size: 21px;
  font-weight: 400;
}

.lang-icon {
  display: grid;
  justify-items: end;
  font-size: 24px;

}

.sing-up-button {
  display: grid;
  grid-row: 5/span 1;
  grid-column: 1/span 3;
  width: 100%;
  margin: 8px 0;
  border: none;
}

.sing-up-wrapper {
  display: grid;
  grid-column-start: 1;
  grid-row-start: 2;
  //align-items: center;
  //justify-items: center;
  margin-top: 45px;
  //margin-top: 4vh;
}

.sign-up-container {
  background-color: rgba(255, 255, 255, 0.2);
}

.wrapper-go-back {
  grid-column: 1 / span 1;
  grid-row: 3/ span 1;
}

.a-go-back {
  cursor: pointer;
  display: grid;
  justify-items: center;
  margin-top: 3px;
  color: #000000;
}

.Split {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 3fr 1fr;
  justify-items: center;
  position: absolute;
  top: 0;
  height: 100%;
  left: 50%;
  width: 100%;
  background-color: #fff;
  //background-color: rgba(0, 0, 0, .05);


  &.enter {
    opacity: 0;
    transition: all 0.6s ease-out;
    display: grid;

  }

  &.enter-active {
    opacity: 1;
    transition: all 0.6s ease-out;
  }

  &.exit {
    opacity: 1;
    transform: translateX(0);
    transition: opacity $transition, transform $transition;
  }

  &.exit-active {
    opacity: 0;
    transform: translateX(100px);
    transition: opacity 200ms, transform $transition;
  }
}

.footer-wrapper {
  display: grid;
  grid-column: 1 / span 4;
  grid-row: 3 / span 1;
}

.footer-login {
  display: grid;
  grid-template-columns: 30vw 8fr 7fr 24vw;
  margin-top: 22vh;
  font-size: 16px;
  line-height: 16px;
  justify-items: end;
}

.footer-login-after {
  display: none
}

@media (max-width: 767px) {
  .footer-login {
    display: none
  }

  .container-activate {
    display: grid;
    grid-column: 2;
    grid-row: 3;
    align-content: center;

    transform: translateY(-28vh) translateX(-5vw);
    transition: all 0.6s ease-out;
  }
}

.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.selectStyle {
  background-color: #804BD8;
}

.optionStyle {
  background-color: #804BD8;
  color: #fff;
}