.filled svg {
    fill: var(--blue);
}

.star svg {
    margin-right: 5px;
}

.editable {
    cursor: pointer;
}