.homeWrapper {
    display: grid;
    grid-template-columns: 5px 100px minmax(320px, 1400px) auto;
    grid-template-rows: auto 1fr auto;
    /*gap: 0 5px;*/
    grid-template-areas:
            ". header header ."
            ". sidebar body ."
            "footer footer footer footer"
}

.homeHeader {
    grid-area: header;
    display: grid;
/*minmax(30px,50px) 1fr 2fr*/
    grid-template-columns: 22fr auto auto 2fr auto auto minmax(30px,80px);
}

.homeSidebar {
    grid-area: sidebar;
}

.homeBody {
    grid-area: body;
    display: grid;
    min-height: 90vh;
    grid-template-columns: .65fr minmax(10px,20px) 1fr;
    grid-template-rows: 1fr 3fr minmax(10px,15px) 1fr 3fr minmax(10px,15px) 1fr 3fr;
    /*gap: 10px 0;*/
    padding: 60px 90px 60px 220px;
/*justify-content: center;*/
    align-items: center;
    justify-items: start;
}

.homeFooter {
    grid-area: footer;
}


@media (max-width: 767px) {
    /*.wrapper {*/
    /*    grid-template-columns: minmax(320px, 1fr);*/
    /*    grid-template-areas:*/
    /*        "header"*/
    /*        "body"*/
    /*        "footer"*/
    /*}*/

    .homeWrapper {
        grid-template-columns: minmax(320px, 1fr);
        grid-template-rows: auto 1fr minmax(50px, 60px);
        grid-template-areas:
            "header"
            "body"
            "footer"
    }

    .homeSidebar {
        display: none;
    }

    .homeHeader {
        display: block;
    }

    .homeFooter {
        display: block !important;
    }

}