
.h1-login {
    color: #fff;
    font-weight: bold;
    margin: 0 0 20px 0;
//padding: 30px;
    position: relative;

}

form {
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 360px;
    width: 360px;
    text-align: center;
    border-radius: 10px;
/*//margin: 15px 0 0 0;*/
}

input,
.input {
/*//background-color: #eee;*/
    position: relative;
/*//margin-bottom: 20px;*/
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid rgba(255, 255, 255, .4);
    color: #fff;
    border-radius: 7px;
    padding: 15px 15px;
//margin: 8px 0;
    width: 300px;
    margin-top: 10px;
}

.login-button {
    position: relative;
    border: 1px solid #fff;
    margin: 25px 0 15px 0;
    transition: transform 80ms ease-in;
}
